import { useState, useEffect, useContext } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import { FiPlusCircle } from "react-icons/fi";
import "./entrySelectiveProcess.css";
import { AuthContext } from "../../contexts/auth";
import { db } from "../../services/firebaseConnection";
import axios from "axios";

import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  updateDoc,
} from "firebase/firestore";

import { useParams, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import listProcess from "../../api/listProcess.json";
import RouteLinks from "../../components/RouteLinks";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validateCPF } from "../../functions/validate-cpf";
import { formatPhoneNumber } from "../../functions/formatPhoneNumber";
import { formatCPF } from "../../functions/formatCPF";
import { formatCep } from "../../functions/formatCep";
import { validateEmail } from "../../functions/validateEmail";

const listRef = collection(db, "customers");

const schema = yup
  .object({
    // mainEmail: yup.string(),
    // birthDate: yup.number().positive().integer().required(),
  })
  .required();

export default function EntrySelectiveProcess() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const [isActive, setIsActive] = useState(false);

  const [cpfIsValid, setCpfIsValid] = useState(null);
  const [phoneMainNumberIsValid, setPhoneMainNumberIsValid] = useState(null);
  const [phoneSecondaryNumberIsValid, setPhoneSecondaryNumberIsValid] =
    useState(null);
  const [mainEmailIsValid, setMainEmailIsValid] = useState(null);
  const [secondaryEmailIsValid, setSecondaryEmailIsValid] = useState(null);

  function handleTextChange(text) {
    if (text !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }

  useEffect(() => {
    async function loadCustomers() {
      let dataProcess = listProcess.filter((item) => item.id == id);
      setData(dataProcess[0]);
    }

    loadCustomers();
  }, [id]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // const onSubmit = (data) => validateData(data);
  const onSubmit = (dataForm) => console.log(dataForm);

  const autoPreencher = () => {
    setValue("birthDate", "2001-07-06");
    setValue("cep", "95330-000");
    setValue("city", "Veranópolis");
    setValue("cpf", "034.384.630-67");
    setValue("houseNumber", "100");
    setValue("mainEmail", "gabriel@email.com");
    setValue("mainPhoneNumber", "(54) 9 9978-6256");
    setValue("name", "Gabriel Gustavo Grams Holz");
    setValue("rg", "123456789");
    setValue("secondaryEmail", "");
    setValue("secondaryPhoneNumber", "");
    setValue("street", "Moacir Durli");
    setValue("uf", "RS");
  };

  const validateData = (data) => {
    console.log(data);
    // if (!validateCPF(data.cpf)) {
    //   alert("cpf invalido");
    // }
  };

  // function formatCPF(cpf) {
  //   // Remove caracteres especiais do CPF (pontos e hífens)
  //   cpf = cpf.replace(/[^\d]/g, "");

  //   // Formata o CPF com pontos e hífen
  //   let cpfFormatado = cpf.replace(
  //     /(\d{3})(\d{3})(\d{3})(\d{2})/,
  //     "$1.$2.$3-$4"
  //   );

  //   setValue("cpf", cpfFormatado);
  //   console.log(cpfFormatado);
  //   return cpfFormatado;
  // }

  const handleEditCpf = (value) => {
    // formatPhoneNumber(value);
    formatCPF(value).then((result) => {
      setCpfIsValid(result.isValid);
      setValue("cpf", result.returnCpf);
    });
  };

  const handleEditCep = (value) => {
    formatCep(value).then((returnCep) => {
      setValue("cep", returnCep);
      if (returnCep) {
        axios
          .get(`https://viacep.com.br/ws/${returnCep}/json/`)
          .then((response) => {
            setValue("city", response.data.localidade);
            setValue("uf", response.data.uf);
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const handleEditPhoneNumber = (value, positionNumber) => {
    // formatPhoneNumber(value);

    formatPhoneNumber(value).then((result) => {
      if (positionNumber === 1) {
        setValue("mainPhoneNumber", result.returnNumber);
        setPhoneMainNumberIsValid(result.isValid);
      } else {
        setValue("secondaryPhoneNumber", result.returnNumber);
        setPhoneSecondaryNumberIsValid(result.isValid);
      }
    });
  };

  const handleEditEmail = (value, positionEmail) => {
    validateEmail(value)
      .then((result) => {
        if (positionEmail === 1) {
          setMainEmailIsValid(result);
        } else {
          setSecondaryEmailIsValid(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyActive = (value) => {
    if (watch(value) !== "") {
      // console.log(watch(value))
      return "Active";
    } else {
      return null;
    }

    // className={watch("name") !== "" ? "Active" : null}
  };

  return (
    <div>
      <Header />

      <div className="content">
        <Title name={`Processo Seletivo: ${data?.processTitle}`}>
          {/* <FiMessageSquare size={25} /> */}
        </Title>
        <RouteLinks
          backRoute="/home"
          routeLinks={[
            { link: "/home", name: "Processos seletivos" },
            { link: `/selective-process/${id}`, name: "Processo seletivo" },
            { link: `/selective-process/${id}/entry`, name: "Inscrição" },
          ]}
        />


<button onClick={()=> autoPreencher()}>preencher</button>
        <form className="form-entry-process" onSubmit={handleSubmit(onSubmit)}>
          {/* nome e genero */}
          <section>
            <div className="float-label" style={{ width: "60%" }}>
              <label className={verifyActive("name")} htmlFor="name">
                Nome completo
              </label>
              <input id="name" type="text" name="name" {...register("name")} />
            </div>

            <div className="float-label">
              <select className="select-gender">
                <option value="">Selecione o gênero</option>
                <option value="f">Feminino</option>
                <option value="m">Masculino</option>
                <option value="o">Outros</option>
              </select>
            </div>
          </section>

          {/* cpf, rg, e data de nascimento */}
          <section>
            <div className="float-label" style={{ width: "30%" }}>
              <label
                className={watch("cpf") !== "" ? "Active" : null}
                style={{
                  border: watch("cpf") !== "" && !cpfIsValid && "1px solid red",
                }}
                htmlFor="cpf"
              >
                CPF
              </label>
              <input
                id="cpf"
                onBlurCapture={() => {
                  handleEditCpf(watch("cpf"));
                }}
                style={{
                  border: watch("cpf") !== "" && !cpfIsValid && "1px solid red",
                }}
                type="text"
                name="cpf"
                {...register("cpf")}
              />
              {errors.cpf && <p>{errors.cpf.message}</p>}
              {watch("cpf") !== "" && !cpfIsValid && (
                <p className="error-message">CPF inválido!</p>
              )}
            </div>

            <div className="float-label" style={{ width: "30%" }}>
              <label
                className={watch("rg") !== "" ? "Active" : null}
                htmlFor="rg"
              >
                RG
              </label>
              <input type="rg" {...register("rg")} />
            </div>

            <div className="float-label" style={{ width: "30%" }}>
              <label
                className={verifyActive("birthDate")}
                style={{ background: "#fff" }}
                htmlFor="birthDate"
              >
                Data de nascimento
              </label>
              <input
                type="date"
                {...register("birthDate", {
                  required: true,
                  pattern: /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/,
                })}
              />
              {errors.date && <span>Por favor, insira uma data válida.</span>}
            </div>
          </section>

          {/* Telefone principal e secundário */}
          <section style={{ justifyContent: "left" }}>
            <div className="float-label" style={{ width: "40%" }}>
              <label
                className={watch("mainPhoneNumber") !== "" ? "Active" : null}
                htmlFor="mainPhoneNumber"
              >
                Telefone principal
              </label>
              <input
                id="mainPhoneNumber"
                onBlurCapture={() => {
                  handleEditPhoneNumber(watch("mainPhoneNumber"), 1);
                }}
                type="text"
                name="mainPhoneNumber"
                {...register("mainPhoneNumber")}
              />
              {watch("mainPhoneNumber") !== "" && !phoneMainNumberIsValid && (
                <p className="error-message">Número inválido!</p>
              )}

              {errors.cpf && <p>{errors.cpf.message}</p>}
            </div>

            <div className="float-label" style={{ width: "40%" }}>
              <label
                className={
                  watch("secondaryPhoneNumber") !== "" ? "Active" : null
                }
                htmlFor="secondaryPhoneNumber"
              >
                Telefone secundário
              </label>

              <input
                onBlurCapture={() => {
                  handleEditPhoneNumber(watch("secondaryPhoneNumber"), 2);
                }}
                type="text"
                name="secondaryPhoneNumber"
                {...register("secondaryPhoneNumber")}
              />
              {watch("secondaryPhoneNumber") !== "" &&
                !phoneSecondaryNumberIsValid && (
                  <p className="error-message">Número inválido!</p>
                )}

              {errors.cpf && <p>{errors.cpf.message}</p>}
            </div>
          </section>

          {/*Email principal e secundário  */}
          <section style={{ justifyContent: "left" }}>
            <div className="float-label" style={{ width: "40%" }}>
              <label className={verifyActive("mainEmail")} htmlFor="mainEmail">
                E-mail principal
              </label>

              <input
                onBlurCapture={() => {
                  handleEditEmail(watch("mainEmail"), 1);
                }}
                type="text"
                name="mainEmail"
                {...register("mainEmail")}
              />
              {watch("mainEmail") !== "" && !mainEmailIsValid && (
                <p className="error-message">Email inválido!</p>
              )}

              {errors.cpf && <p>{errors.cpf.message}</p>}
            </div>

            <div className="float-label" style={{ width: "40%" }}>
              <label
                className={verifyActive("secondaryEmail")}
                htmlFor="secondaryEmail"
              >
                E-mail secundário
              </label>

              <input
                onBlurCapture={() => {
                  handleEditPhoneNumber(watch("secondaryEmail"), 2);
                }}
                type="text"
                name="secondaryEmail"
                {...register("secondaryEmail")}
              />
              {watch("secondaryEmail") !== "" && !secondaryEmailIsValid && (
                <p className="error-message">Email inválido!</p>
              )}

              {errors.cpf && <p>{errors.cpf.message}</p>}
            </div>
          </section>

          {/* cep, cidade e UF */}
          <section>
            <div className="float-label" style={{ width: "30%" }}>
              <label className={verifyActive("cep")} htmlFor="cep">
                CEP
              </label>
              <input
                id="cep"
                onBlurCapture={() => {
                  handleEditCep(watch("cep"));
                }}
                type="text"
                name="cep"
                {...register("cep")}
              />
            </div>

            <div className="float-label" style={{ width: "30%" }}>
              <label className={verifyActive("city")} htmlFor="city">
                Cidade
              </label>
              <input type="text" {...register("city")} />
            </div>

            <div className="float-label" style={{ width: "30%" }}>
              <label className={verifyActive("uf")} htmlFor="uf">
                UF
              </label>
              <input type="text" {...register("uf")} />
            </div>
          </section>

          {/* logradouro e numero */}
          <section>
            <div className="float-label" style={{ width: "60%" }}>
              <label className={verifyActive("street")} htmlFor="street">
                Logradouro
              </label>
              <input
                id="street"
                type="text"
                name="street"
                {...register("street")}
              />
            </div>

            <div className="float-label" style={{ width: "30%" }}>
              <label
                className={verifyActive("houseNumber")}
                htmlFor="houseNumber"
              >
                Número
              </label>
              <input type="text" {...register("houseNumber")} />
            </div>
          </section>

          {/* <input type="file" id="documento" {...register("documento", { required: true })} /> */}
          <input type="submit" value="Concluir inscrição" className="button-submit" />
        </form>
      </div>

      <Footer />
    </div>
  );
}
