import Header from "../../components/Header";


export default function Teste() {

    return (
      <div>
        <Header/>
        <h3>é um teste</h3>
    </div>
  );
}
