import { useState, useEffect, useContext } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import { FiPlusCircle } from "react-icons/fi";
import "./selectiveProcess.css";
import { AuthContext } from "../../contexts/auth";
import { db } from "../../services/firebaseConnection";

import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  updateDoc,
} from "firebase/firestore";

import { useParams, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import listProcess from "../../api/listProcess.json";
import RouteLinks from "../../components/RouteLinks";

const listRef = collection(db, "customers");

export default function SelectiveProcess() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function loadCustomers() {
      let dataProcess = listProcess.filter((item) => item.id == id);
      setData(dataProcess[0]);
    }

    loadCustomers();
  }, [id]);

  return (
    <div>
      <Header />

      <div className="content">
        <Title name={`Processo Seletivo: ${data?.processTitle}`}>
          {/* <FiMessageSquare size={25} /> */}
        </Title>
        <RouteLinks 
        backRoute="/home"
        routeLinks={[{link:"/home", name: "Processos seletivos"}, {link:`/selective-process/${id}`, name: "Processo seletivo"}]}/>
        <section className="view-details-selective-process">
          <div className="view-details-selective-process-labels">
            <label>
              Categoria: <span>{data?.jobCategory}.</span>
            </label>
            <label>
              Data da publicação: <span>{data?.publicationDate}.</span>
            </label>
            <label>
              Data da abertura: <span>{data?.startDate}.</span>
            </label>
            <label>
              Local: <span>{data?.local}.</span>
            </label>
          </div>
          <div className="view-details-selective-process-complement">
            <p>{data?.introductoryText}</p>

            <ul>
              {data?.jobpositions.map((doc, index) => {
                if (index == data.jobpositions.length - 1) {
                  return <li>{doc}.</li>;
                } else {
                  return <li>{doc}</li>;
                }
              })}
            </ul>

            <p>{data?.conclusionText}</p>
          </div>
        </section>

              <Link to={`/selective-process/${id}/entry`}>Inscreva-se</Link>

      </div>

      <Footer />
    </div>
  );
}
