import { Routes, Route } from 'react-router-dom'

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Home from '../pages/Home'
import Profile from '../pages/Profile'
import Customers from '../pages/Customers'
import New from '../pages/New'
import SelectiveProcess from '../pages/SelectiveProcess'

import Private from './Private'
import Teste from '../pages/Teste';
import EntrySelectiveProcess from '../pages/EntrySelectiveProcess';

function RoutesApp(){
  return(
    <Routes>
      <Route path="/" element={ <SignIn/> } />
      <Route path="/register" element={ <SignUp/> } />
      <Route path="/home" element={ <Home/> } />
      <Route path="/teste" element={ <Teste/> } />
          
      <Route path="/profile" element={ <Private><Profile/></Private> } />

      <Route path="/customers" element={<Private><Customers/></Private>} />
      
      <Route path="/new" element={<Private><New/></Private>} />

      <Route path="/new/:id" element={<Private><New/></Private>} />

      <Route path="/selective-process/:id" element={<SelectiveProcess/>} />
      <Route path="/selective-process/:id/entry" element={<Private><EntrySelectiveProcess/></Private>} />
   
    </Routes>
  )
}

export default RoutesApp;