import "./routeLinks.css";
import { Link } from "react-router-dom";

export default function RouteLinks({
  children,
  name,
  backRoute,
  address,
  routeLinks,
  routeLink2,
}) {
  return (
    <div className="view-links">
      <Link to={backRoute} className="">
        Voltar
      </Link>
      <span className="address">
        {" "}
        {routeLinks?.map((link, index) => {
          if (index == routeLinks?.length - 1) {
            return <Link key={index} to={link?.link}>{link?.name}</Link>;
          } else {
            return <Link  key={index} to={link?.link}>{link?.name} /</Link>;
          }
        })}
        
      </span>
    </div>
  );
}
