import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";

import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiPlus, FiMessageSquare, FiSearch, FiEdit2 } from "react-icons/fi";
import listProcess from "../../api/listProcess.json";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  orderBy,
  limit,
  startAfter,
  query,
} from "firebase/firestore";
import { db } from "../../services/firebaseConnection";

import { format } from "date-fns";
import Modal from "../../components/Modal";

import "./home.css";

const listRef = collection(db, "selectiveProcess");

export default function Home() {
  const { logout } = useContext(AuthContext);

  const [selectiveProcess, setSelectiveProcess] = useState(listProcess);
  const [loading, setLoading] = useState(true);

  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();
  const [loadingMore, setLoadingMore] = useState(false);

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

  useEffect(() => {
    async function loadSelectiveProcess() {
      const q = query(listRef, orderBy("created", "desc"), limit(5));

      const querySnapshot = await getDocs(q);
      // setSelectiveProcess([]);

      await updateState(querySnapshot);

      setLoading(false);
    }

    loadSelectiveProcess();

    return () => {};
  }, []);

  async function updateState(querySnapshot) {
    const isCollectionEmpty = querySnapshot.size === 0;

    if (!isCollectionEmpty) {
      let lista = [];

      querySnapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          assunto: doc.data().assunto,
          cliente: doc.data().cliente,
          clienteId: doc.data().clienteId,
          created: doc.data().created,
          createdFormat: format(doc.data().created.toDate(), "dd/MM/yyyy"),
          status: doc.data().status,
          complemento: doc.data().complemento,
        });
      });

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]; // Pegando o ultimo item

      // setSelectiveProcess(selectiveProcess => [...selectiveProcess, ...lista])
      setLastDocs(lastDoc);
    } else {
      setIsEmpty(true);
    }

    setLoadingMore(false);
  }

  async function handleMore() {
    setLoadingMore(true);

    const q = query(
      listRef,
      orderBy("created", "desc"),
      startAfter(lastDocs),
      limit(5)
    );
    const querySnapshot = await getDocs(q);
    await updateState(querySnapshot);
  }

  function toggleModal(item) {
    setShowPostModal(!showPostModal);
    setDetail(item);
  }

  if (loading) {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Processos Seletivos">
            <FiMessageSquare size={25} />
          </Title>

          <div className="container home">
            <span>Buscando processos seletivos...</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Header />

        <div className="content">
          <Title name="Processos Seletivos">
            <FiMessageSquare size={25} />
          </Title>

          <>
            {selectiveProcess.length === 0 ? (
              <div className="container home">
                <span>Nenhum processo encontrado...</span>
              </div>
            ) : (
              <div className="view-cards-process">
                {selectiveProcess.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      className="card-process-item"
                      to={`/selective-process/${item.id}`}
                    >
                      {/* <div className="card-process-view-status"><span>{item.status}</span></div> */}
                      <div className="view-title-process">
                        <span>{item.processTitle}</span>
                      </div>

                      <span>
                        Descrição: <span>{item.jobDescription}</span>
                      </span>
                      <span>
                        Categoria: <span>{item.jobCategory}</span>.
                      </span>
                      <span>
                        Cargo(s):{" "}
                        <span>
                          {item.jobpositions.map((doc, index) => {
                            if (index == item.jobpositions.length - 1) {
                              return `${doc}.`;
                            } else {
                              return `${doc}, `;
                            }
                          })}
                        </span>
                      </span>
                      <div className="view-date">
                        <span>Data de Publicação: {item.startDate}</span>
                        <span>{item.status}</span>
                      </div>
                    </Link>
                  );
                })}

                {loadingMore && <h3>Buscando mais processos...</h3>}
                {!loadingMore && !isEmpty && (
                  <button className="btn-more" onClick={handleMore}>
                    Buscar mais
                  </button>
                )}
              </div>
            )}
          </>
        </div>

        {showPostModal && (
          <Modal
            conteudo={detail}
            close={() => setShowPostModal(!showPostModal)}
          />
        )}
      </div>
    );
  }
}
