async function formatPhoneNumber(phoneNumber) {
  // Remove caracteres especiais do número de telefone
  phoneNumber = phoneNumber.replace(/[^\d]/g, "");

  // Verifica se o número de telefone tem o nono dígito
  const hasNineDigits = phoneNumber.length === 11;
  let isValid = false;
  let returnNumber = phoneNumber;
  if (phoneNumber.length < 12 && phoneNumber.length > 9) {
    isValid = true;
    // Formata o número de telefone com ou sem o nono dígito
    if (hasNineDigits) {
      returnNumber = phoneNumber.replace(
        /(\d{2})(\d{1})(\d{4})(\d{4})/,
        "($1) $2 $3-$4"
      );

      return { returnNumber, isValid };
    } else {
      returnNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");

      return { returnNumber, isValid };
    }
  } else {
    return { returnNumber, isValid };
  }
}

export { formatPhoneNumber };
