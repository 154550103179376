import "./title.css";
import { Link } from "react-router-dom";

export default function Title({
  children,
  name,
  backRoute,
  address,
  routeLink1,
  routeLink2,
}) {
  return (
    <div className="view-title">
      <div className="title">
        <span>{name}</span>
      </div>

    </div>
  );
}
