async function formatCep(cep) {
    cep = cep.replace(/[^\d]/g, "");

  if (cep && cep.length === 8) {
    return cep.replace(/^(\d{5})(\d{3})?$/, "$1-$2");
  }
  return "";
}

export { formatCep };
