import { useContext } from "react";
import avatarImg from "../../assets/avatar.png";
import logoImg from "../../assets/logo.png";
import { Link } from "react-router-dom";

import { AuthContext } from "../../contexts/auth";
import { FiHome, FiUser, FiSettings } from "react-icons/fi";
import { RxExit } from "react-icons/rx";
import "./footer.css";

export default function Footer() {
  const { user, logout } = useContext(AuthContext);

  return (
    <div className="bottombar">
      
    <span>...</span>
          
    <span>...</span>      
    <span>...</span>      
    <span>...</span>      
    <span>...</span>      
    <span>...</span>      
    <span>...</span>      
    <span>...</span>      
    <span>...</span>      
    <span>...</span>      
    <span>...</span>      
    <span>...</span>
    </div>
  );
}
