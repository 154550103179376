
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAoWXtZnnT4CNraneaOsigMIJKAL8Bv9R0",
  authDomain: "seletivofacil.firebaseapp.com",
  projectId: "seletivofacil",
  storageBucket: "seletivofacil.appspot.com",
  messagingSenderId: "157706477026",
  appId: "1:157706477026:web:ed07573986dcce90a42dbb",
  measurementId: "G-FN4E96196E"
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { auth, db, storage };